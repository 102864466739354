import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
  styled,
  Button,
  Typography
  //Dialog,
  ///DialogTitle,
  //DialogContent,
  //DialogActions
} from '@mui/material';

import {
  fetchDirectoryThunk,
  conflictDirectoryThunk,
  copyDirectoryThunk
} from 'entities/documentDirectory';
import { copyDocumentThunk } from 'entities/documents';
import { checkUserTask } from 'entities/userTask';
import { DirectoryExplorer } from 'features/directoryExplorer';
import { ConflictDialog, rules, statuses } from '../Conflict';
import { BaseDialog } from '../BaseDialog';

export const CopyDialog = ({ entity, currentDirectoryId, onClose }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [conflict, setConflict] = React.useState(false);

  const handleCloseConflictDialog = () => {
    setLoading(false);
    setConflict(false);
  };

  const handleCopy = async (rule = rules.skip) => {
    setConflict(false);
    setLoading(true);

    const { TaskId } = await dispatch(
      copyDirectoryThunk({
        Id: entity.Id,
        toDirectoryId: data?.selected?.Id ?? data?.current?.Id,
        rule
      })
    ).unwrap();

    const timer = () => {
      setTimeout(async () => {
        const response = await checkUserTask(TaskId);

        if (![statuses.done, statuses.error].includes(response.Status)) {
          timer();
        } else {
          dispatch(fetchDirectoryThunk());
          setLoading(false);
          onClose();
        }
      }, 1000);
    };

    timer();
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (!entity.IsFolder) {
      await dispatch(
        copyDocumentThunk({
          directoryId: data?.selected?.Id ?? data.current.Id,
          id: entity.Id
        })
      ).unwrap();

      dispatch(fetchDirectoryThunk());

      setLoading(false);

      onClose();

      return;
    }

    const { Conflict } = await dispatch(
      conflictDirectoryThunk({
        toDirectoryId: data?.selected?.Id ?? data?.current?.Id,
        ids: [entity.Id]
      })
    ).unwrap();

    setConflict(Conflict);

    if (!Conflict) {
      handleCopy();
    }
  };

  return (
    <>
      <BaseDialog
        loading={loading}
        title="Копировать"
        onClose={onClose}
        actions={
          <>
            <StyledButton
              variant="outlined"
              sx={{ width: 'calc(50% - 20px)' }}
              onClick={onClose}
            >
              Отмена
            </StyledButton>
            {/* запрещаем копировать в корень комнат */}
            {data?.current?.Type !== 4096 && (
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  bgcolor: '#ff6a6a !important',
                  width: 'calc(50% - 20px)'
                }}
              >
                Копировать
              </Button>
            )}
          </>
        }
      >
        <Typography>
          Копируемые объекты будут видны все пользователям, у кого есть права
          доступа к выбраной папке.
        </Typography>
        <DirectoryExplorer
          initialDirectoryId={currentDirectoryId}
          data={data}
          onChange={setData}
        />
      </BaseDialog>
      <ConflictDialog
        entityId={entity.Id}
        directoryId={data?.selected?.Id ?? data?.current?.Id}
        open={conflict}
        onCopyStart={() => {
          setLoading(true);
          handleCloseConflictDialog();
        }}
        onCopyEnd={() => {
          setLoading(false);
          onClose();
        }}
        onClose={handleCloseConflictDialog}
      />
    </>
  );
};

const StyledButton = styled((props) => (
  <Button
    variant="outlined"
    color="inherit"
    sx={{ color: 'text.primary', borderColor: 'divider' }}
    {...props}
  />
))({});
