import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Dialog,
  DialogContent,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem
} from '@mui/material';
import { Close, Groups, Check } from '@mui/icons-material';

import { selectViewer } from 'entities/auth';
import {
  selectRoomsDirectory,
  addRoomThunk,
  deleteDirectoryThunk,
  unshareDirectoryThunk,
  shareDirectoryThunk,
  updateDirectoryThunk,
  fetchRoomsDirectoryThunk
} from 'entities/documentDirectory';
import { fetchShareInfo } from 'entities/share';
import { showSnackbar } from 'features/Snackbar';
import { getUserInfo } from 'entities/user';

import { accessType, validation } from 'shared/constants';
import { LoadingBox } from 'shared/components';

import { ConfirmDialog } from '../Confirm';
import { SearchUserDialog } from '../SearchUser';

const colors = [
  '#CED4DA',
  '#FFC078',
  '#FFE066',
  '#C0EB75',
  '#8CE99A',
  '#66D9E8',
  '#74C0FC',
  '#91A7FF',
  '#B197FC',
  '#E599F7',
  '#FFA8A8'
];

export const RoomDialog = ({ room, onClose }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const viewer = useSelector(selectViewer);
  const roomsDirectory = useSelector(selectRoomsDirectory);

  const [searchUserDialogOpen, setSearchUserDialogOpen] = useState(false);
  const [shareInfo, setShareInfo] = useState(null);
  const [users, setUsers] = useState([]);
  const [usersAccessTypes, setUsersAccessTypes] = React.useState([]);
  const [form, setForm] = useState({
    name: '',
    description: '',
    color: colors[0]
  });
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const getUserInitials = (user) =>
    `${user.Surname ? user.Surname[0] : '-'}${
      user.FirstName ? user.FirstName[0] : '-'
    }`;

  const owner = {
    Avatar: room?.UserAvatar ?? viewer.Avatar,
    Name: room?.UserName ?? viewer.Name,
    Initials: room?.UserInitials ?? getUserInitials(viewer)
  };

  const init = useCallback(async () => {
    if (!room) return;

    const shareInfo = await dispatch(
      fetchShareInfo({
        entityId: room.Id,
        entityType: 'Directory'
      })
    ).unwrap();

    const sharedUsers = shareInfo.UserAccessRights.map((item) => ({
      Id: item.PrincipalId,
      access: item.Type
    }));

    const sharedUsersInfo = await getUserInfo(
      sharedUsers.map((item) => item.Id)
    );

    sharedUsers.forEach((user) => {
      const userInfo = sharedUsersInfo.find((info) => info.Id === user.Id);

      if (userInfo) {
        user.FirstName = userInfo.FirstName;
        user.Surname = userInfo.Surname;
        user.Email = userInfo.Email;
        user.Avatar = userInfo.Avatar;
        user.Name = userInfo.Name;
        user.Initials = getUserInitials(userInfo);
      }
    });

    setShareInfo(shareInfo);
    setUsers(sharedUsers);

    setUsersAccessTypes(
      sharedUsers.map((item) => ({ userId: item.Id, value: item.access }))
    );
  }, [dispatch, room]);

  const handleFormChange = (payload) =>
    setForm((state) => ({ ...state, ...payload }));

  const handleSearchUsersSuccess = (data) => {
    setUsers((state) =>
      [
        ...state,
        ...(state.find((item) => item.Id === data.Id) ? [] : [data])
      ].map((item) => ({ ...item, Initials: getUserInitials(item) }))
    );

    setUsersAccessTypes((state) => [
      ...state,
      { userId: data.Id, value: accessTypeOptions[0].value }
    ]);
  };

  const handleUserAccessTypeChange = (userId) => (event) =>
    setUsersAccessTypes((state) =>
      state.map((item) =>
        item.userId !== userId ? item : { userId, value: event.target.value }
      )
    );

  const handleUserRemove = (userId) => {
    setUsers((state) => state.filter((item) => item.Id !== userId));
    setUsersAccessTypes((state) =>
      state.filter((item) => item.userId !== userId)
    );
  };

  const handleShare = async (roomId) => {
    const unsharedUserIds = !shareInfo
      ? []
      : shareInfo.UserAccessRights.filter(
          (item) => !users.find((user) => user.Id === item.PrincipalId)
        ).map((item) => item.PrincipalId);

    if (unsharedUserIds.length) {
      await dispatch(
        unshareDirectoryThunk({
          Ids: [roomId],
          AccessType: accessType.Deny,
          ToUserIds: unsharedUserIds
        })
      ).unwrap();
    }

    const payload = accessTypeOptions.reduce((result, option) => {
      const AccessType = option.value;
      const ToUserIds = usersAccessTypes
        .filter((item) => item.userId && item.value === AccessType)
        .map((item) => item.userId);

      if (!ToUserIds.length) return result;

      return result.concat({
        AccessType,
        ToUserIds,
        ToRoleIds: [],
        Ids: [roomId]
      });
    }, []);

    await Promise.all(
      payload.map(
        (request) =>
          new Promise((resolve, reject) =>
            dispatch(shareDirectoryThunk(request))
              .unwrap()
              .then(resolve)
              .catch(reject)
          )
      )
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!form.name) {
      dispatch(
        showSnackbar({
          type: 'error',
          message: 'Название комнаты не заполнено'
        })
      );

      return;
    }

    if (form.name.length > validation.baseMaxLengthInput) {
      dispatch(
        showSnackbar({
          type: 'error',
          message: `Длина названия комнаты не должна превышать ${validation.baseMaxLengthInput} симовлов`
        })
      );

      return;
    }

    try {
      setLoading(true);

      if (!room) {
        const newRoom = await dispatch(
          addRoomThunk({
            Name: form.name,
            Description: form.description,
            Color: form.color,
            Order: 100,
            Type: 'Default',
            IconId: null,
            RoleIds: [],
            UserIds: [],
            ParentId: roomsDirectory.Id
          })
        ).unwrap();

        await handleShare(newRoom.Id);

        dispatch(
          showSnackbar({ message: `Комната ${form.name} успешно добавлена` })
        );
      } else {
        await dispatch(
          updateDirectoryThunk({
            Id: room.Id,
            Name: form.name,
            Description: form.description,
            Color: form.color
          })
        ).unwrap();

        await handleShare(room.Id);
      }

      dispatch(fetchRoomsDirectoryThunk());

      onClose();
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRoom = async () => {
    try {
      setDeleteLoading(true);
      await dispatch(deleteDirectoryThunk(room.Id)).unwrap();

      dispatch(
        showSnackbar({ message: `Комната ${room.Name} успешно удалена` })
      );

      dispatch(fetchRoomsDirectoryThunk());

      if (window.location.pathname.includes(`/${room.Id}`)) {
        const firstChild = roomsDirectory.Children?.filter(
          (item) => item.Id !== room.Id
        )?.[0];
        navigate(!firstChild ? '/docs' : `/rooms/${firstChild.Id}`);
      }

      onClose();
    } finally {
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    setForm({
      name: room?.Name ?? '',
      description: room?.Description ?? '',
      color: room?.Color ?? colors[0]
    });

    return () => setForm({ name: '', description: '', color: colors[0] });
  }, [room]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <Dialog fullWidth={true} open={true} onClose={onClose}>
        <LoadingBox loading={loading}>
          <DialogContent sx={{ pt: 3 }}>
            <form onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      bgcolor: form.color,
                      height: 40,
                      width: 40,
                      borderRadius: 1,
                      mr: 0.5
                    }}
                  >
                    <Groups sx={{ fontSize: 26, color: '#fff' }} />
                  </Stack>

                  <TextField
                    variant="standard"
                    size="small"
                    label="Название комнаты"
                    value={form.name}
                    sx={{ flexGrow: 1 }}
                    onChange={(event) =>
                      handleFormChange({ name: event.target.value })
                    }
                  />
                  <IconButton onClick={onClose}>
                    <Close />
                  </IconButton>
                </Stack>
                <Stack direction="row" spacing={1}>
                  {colors.map((color) => (
                    <ColorItem
                      key={color}
                      color={color}
                      selected={color === form.color}
                      onClick={() => handleFormChange({ color })}
                    />
                  ))}
                </Stack>
                <TextField
                  multiline
                  rows={2}
                  label="Описание"
                  value={form.description}
                  onChange={(event) =>
                    handleFormChange({ description: event.target.value })
                  }
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>Участники</Typography>
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => setSearchUserDialogOpen(true)}
                    sx={{ borderColor: '#ccc' }}
                  >
                    Добавить
                  </Button>
                </Stack>
                <Stack spacing={1.5}>
                  <UserItem user={owner} />
                  {users.map((user) => (
                    <UserItem
                      key={user.Id}
                      user={user}
                      accessType={
                        usersAccessTypes.find((item) => item.userId === user.Id)
                          .value
                      }
                      onAccessTypeChange={handleUserAccessTypeChange(user.Id)}
                      onRemove={() => handleUserRemove(user.Id)}
                    />
                  ))}
                </Stack>
                <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
                  {!!room && (
                    <Button
                      variant="text"
                      color="error"
                      onClick={() => setDeleteConfirmOpen(true)}
                    >
                      Удалить
                    </Button>
                  )}
                  <Button
                    disabled={loading}
                    type="submit"
                    variant="contained"
                    sx={{ bgcolor: '#ff6a6a !important' }}
                  >
                    {!room ? 'Создать' : 'Сохранить'}
                  </Button>
                </Stack>
              </Stack>
            </form>
          </DialogContent>
        </LoadingBox>
      </Dialog>
      {deleteConfirmOpen && (
        <ConfirmDialog
          loading={deleteLoading}
          title="Удаление комнаты"
          content="Вы уверены, что хотите удалить комнату?"
          actions={{ confirm: { color: 'error', text: 'Удалить' } }}
          onCancel={() => setDeleteConfirmOpen(false)}
          onConfirm={async () => {
            await handleDeleteRoom();

            setDeleteConfirmOpen();
          }}
        />
      )}
      <SearchUserDialog
        open={searchUserDialogOpen}
        excludeUserIds={[viewer.Id]}
        onClose={() => setSearchUserDialogOpen(false)}
        onSuccess={handleSearchUsersSuccess}
      />
    </>
  );
};

const ColorItem = ({ color, selected, ...props }) => (
  <Stack
    alignItems="center"
    justifyContent="center"
    sx={{
      height: 24,
      width: 24,
      borderRadius: '50%',
      bgcolor: color,
      cursor: 'pointer'
    }}
    {...props}
  >
    {selected && <Check sx={{ color: '#fff', fontSize: 17 }} />}
  </Stack>
);

const UserItem = ({ user, accessType, onAccessTypeChange, onRemove }) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <Avatar src={user.Avatar} sx={{ height: 30, width: 30 }}>
      <Typography sx={{ fontSize: 12 }}>{user.Initials}</Typography>
    </Avatar>
    <Typography noWrap sx={{ fontSize: 14, width: 300 }}>
      {user.Name}
    </Typography>
    <Stack sx={{ flexShrink: 0, ml: 'auto !important' }}>
      {accessType === undefined && (
        <Typography
          sx={{
            fontSize: 12,
            color: 'text.secondary',
            marginLeft: 'auto !important'
          }}
        >
          Владелец
        </Typography>
      )}
      {accessType !== undefined && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Select
            variant="standard"
            size="small"
            value={accessType}
            onChange={onAccessTypeChange}
          >
            {accessTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
          <IconButton size="small" onClick={onRemove}>
            <Close fontSize="small" />
          </IconButton>
        </Stack>
      )}
    </Stack>
  </Stack>
);

const accessTypeOptions = [
  { name: 'Чтение', value: accessType.Read },
  { name: 'Комментирование', value: accessType.Comment },
  { name: 'Запись', value: accessType.Write },
  { name: 'Полный доступ', value: accessType.Full },
  { name: 'Запрещён', value: accessType.Deny, disabled: true }
];
